.top-container-fluid {
  height: 3rem;
}
.hero-section-main-container {
  background-image: url("../../assets/images/new/bg-box.svg"),
    url("../../assets/images/new/bg-box-2.svg");
  background-position: 5% 5%, 60% 87%;
  /* background-size: contain; */
  background-repeat: no-repeat;
  margin-top: 3rem;
  height: 93vh;
  width: 100%;
}
.hero-heading-container {
  width: 100%;
  max-width: 400px;
}
.video-container {
  width: 100%;
  max-width: 910px;
  border-radius: 10px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07);
}
.bg_footer{
  background: rgba(255, 255, 255, 0.3);
}
.peace-icon{
  height: 30px;
}

@media only screen and (max-width: 1200px) {
  .hero-section-main-container {
    background-image: none;
    height: auto;
  }
  .hero-heading-container {
    width: 100%;
    max-width: 100%;
  }
  .video-container {
    width: 100%;
    max-width: 900px;
  }
}

@media only screen and (max-width: 768px) {
  .backstory-img {
    max-width: 180px;
    width: 100%;
    height: auto;
  }
  .peace-icon{
    height: 24px;
  }
}
