body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 2px !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #7065e4;
  border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  height: 10px !important;
  background: #2ad3e7;
  border-radius: 10px;
}

.main-container-bg {
  background-image: url("./assets/images/new/bg-lines.svg");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  /* opacity: ; */
}
.footer_bg_img {
  background-color: white;
  background-image: url("./assets/images/footer-bg.png");
  background-position: start;
  background-repeat: no-repeat;
  background-size: cover;
}
.cursor_pointer {
  cursor: pointer;
}
.primary_color {
  color: #364048;
}
.fw_400 {
  font-weight: 400;
}
.fw_500 {
  font-weight: 500;
}
.fw_600 {
  font-weight: 600;
}
.fw_700 {
  font-weight: 700;
}
.fs_16 {
  font-size: 16px;
}
.fs_17 {
  font-size: 17px;
}
.fs_18 {
  font-size: 18px;
}
.fs_20 {
  font-size: 20px;
}
.fs_22 {
  font-size: 22px;
}
.fs_24 {
  font-size: 24px;
}
.fs_25 {
  font-size: 25px;
}
.fs_30 {
  font-size: 30px;
}
.fs_40 {
  font-size: 40px;
}
.fs_50 {
  font-size: 50px;
}
.line_ht {
  line-height: 50px;
}
.border_radius {
  border-radius: 18px 18px 18px 18px;
}

@media only screen and (max-width: 1400px) {
  .fs_xl_40 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 1200px) {
  .fs_lg_30 {
    font-size: 30px;
  }
  .fs_lg_14 {
    font-size: 14px;
  }
  .line_ht {
    line-height: 20px;
  }
  .footer_bg_img {
    background-color: #343a40;
  }
}
@media only screen and (max-width: 992px) {
  .main-container-bg {
    background-size: contain;
  } 
  .fs_md_15 {
    font-size: 15px;
  }
  .fs_md_16 {
    font-size: 16px;
  }
  .fs_md_18 {
    font-size: 18px;
  }
  .fs_md_22 {
    font-size: 22px;
  }
  .fs_md_25 {
    font-size: 25px;
  }
  .fs_md_30 {
    font-size: 30px;
  }
  .card-container {
    height: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .fs_sm_16 {
    font-size: 16px;
  }
  .fs_sm_18 {
    font-size: 18px;
  }
  .fs_sm_22 {
    font-size: 22px;
  }
  .fs_sm_25 {
    font-size: 25px;
  }
  .fs_sm_30 {
    font-size: 30px;
  }
}

/* --------------------------------- ANIMATIONS --------------------------------- */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
