.navbar {
  list-style: none;
  overflow: hidden;
  border-bottom: 1px solid rgba(231, 229, 255, 1);
}
.ff_nunito_sans {
  font-family: "Nunito Sans", sans-serif;
}
.ff_poppins {
  font-family: "Poppins", sans-serif;
}

.blue_text {
  color: rgba(112, 101, 228, 1);
}
.bg_blue {
  background: rgba(231, 229, 255, 0.5);
}
.light_blue {
  color: rgba(42, 211, 231, 1);
}

.text-color {
  color: rgba(116, 127, 158, 1);
}
.heading-color {
  color: rgba(38, 44, 61, 1);
}
.bg_img_lines {
  background-image: url("./assets/images/Union.svg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}
.responsive_bg {
  height: 600px;
}
.card_bg {
  background: rgba(243, 242, 255, 1);
}
.responsive_bg {
  height: auto;
}
/* /* @media only screen and (max-width: 776px) {
  .fs_42{
    font-size: 28px;
  }
  .fs_40{
    font-size: 26px;
  }
  .fs_30{
    font-size: 23px;
  }
  .fs_25{
    font-size: 19px;
  }
  .fs_22{
    font-size: 17px;
  }
  .responsive_bg{
    height: auto;
  }
}
@media only screen and (max-width: 380px) {
  .fs_15{
    font-size: 14px;
  }
}
@media only screen and (min-width: 992px) {
  .top_ht{
    padding-top: 7rem !important;
  }
}
@media only screen and (max-width: 992px) and (min-width: 576px) {
  .align_items{
    padding-left: 150px;
  }
} 
.vedio_width{
  width: 100%;
  height: auto;
} */
